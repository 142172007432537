import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'closeAlertBtn' ]

  async purchaseFreePlan(event) {
    event.preventDefault()
    const response = await post(event.target.href, {responseKind: 'json'})
    
    if (response.ok) {
      const responseData = await response.json;
      this.closeAlertBtnTarget.click();
      if (responseData.status !== 'error') {
        this.createAndClickOpenInfoBtn();
        // toaster("success", I18n.profile.purchase.free.success.title, [I18n.profile.purchase.free.success.message]);
      } else {
        toaster("error", I18n.profile.purchase.free.error.title, [I18n.profile.purchase.free.error.message]);
        console.error('Error data:', responseData.errorData);
      }
    }
  }

  clearSearchParamsAndReload() {
    const url = new URL(window.location.href);
    window.history.replaceState(null, null, url.toString());
    Turbo.visit(url.toString(), {action: 'replace'}); // window.location.reload();
  }

  createAndClickOpenInfoBtn() {
    const hiddenButton = document.createElement("button");
    hiddenButton.type = "button";
    hiddenButton.style.display = "none";
    hiddenButton.dataset.bsToggle = "modal";
    hiddenButton.dataset.bsTarget = "#modalInfo";
    this.element.appendChild(hiddenButton);
    hiddenButton.click();
    hiddenButton.remove();
  }
}
