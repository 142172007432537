import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [
    'form',
    'citySelect',
    'countrySelect',
    'onlineTypeSelect',
    'publicHiddenInput',
    'nameHiddenInput',
    'budgetHiddenInput',
    'publicNameWrapper',
    'alreadyHiddenAction',
    'publicBudgetWrapper',
    'alreadyPublicWrapper',
    'alreadyPublicNameInput',
    'alreadyPublicBudgetInput',
    'publicBudgetInput',
    'publicNameInput'
  ]

  static values = {
    url: String,
    uuidAuth: String,
    budgetPositive: Boolean,
    turboFrame: { type: String, default: 'modal' }
  }

  connect() {
    this.restoreFormDataIfExists()
    this.onlineTypeSelect()
    this.initializePublicCheckbox()
  }

  onlineTypeSelect() {
    if (!this.hasOnlineTypeSelectTarget) return

    const onlineSelected = this.onlineTypeSelectTarget.value === "false"
    const countrySelectWrapper = this.countrySelectTarget.closest('.form-group.service_request_country_id')
    const citySelectWrapper = this.citySelectTarget.closest('.form-group.service_request_city_id')
    countrySelectWrapper.classList.toggle('d-none', onlineSelected)
    citySelectWrapper.classList.toggle('d-none', onlineSelected)

    if (onlineSelected) {
      this.citySelectTarget.removeAttribute("required")
      this.countrySelectTarget.removeAttribute("required")
    } else {
      this.citySelectTarget.setAttribute("required", "")
      this.countrySelectTarget.setAttribute("required", "")
    }
  }

  restoreFormDataIfExists() {
    if (!this.hasUuidAuthValue) return;

    const savedData = localStorage.getItem(`${this.formIdentifier}-${this.uuidAuthValue}`);
    if (savedData) {
      const formData = JSON.parse(savedData);
      const form = this.formTarget;
      if (form) {
        Object.keys(formData).forEach(key => {
          const input = form.querySelector(`[name="${key}"]`);
          if (input) {
            if (input.classList.contains('tom-select')) {
              const tomSelectInterval = setInterval(() => {
                if (input.tomselect) {
                  const savedOption = formData[key];
                  input.tomselect.addOption({ value: savedOption.value, text: savedOption.text });
                  input.tomselect.setValue(savedOption.value);
                  clearInterval(tomSelectInterval);
                }
              }, 100);
            } else if (input.tagName === 'SELECT' && typeof formData[key] === 'object') {
              const savedOption = formData[key];
              input.value = savedOption.value;
              const option = Array.from(input.options).find(opt => opt.value === savedOption.value);
              if (option) option.text = savedOption.text;
            } else {
              input.value = formData[key];
            }
          }
        });
      }
    }
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(`${this.formIdentifier}-`)) {
        localStorage.removeItem(key);
      }
    });
  }

  performNameAttributes(checked) {
    if (!this.hasPublicNameInputTarget) return

    const publicNameInput = this.publicNameInputTarget
    this.publicNameWrapperTarget.classList.toggle('d-none', !checked)
    publicNameInput.disabled = !checked
    publicNameInput.setAttribute('required', checked)
    this.nameHiddenInputTarget.disabled = checked
  }

  performBudgetAttributes(checked) {
    if (!this.hasPublicBudgetInputTarget) return

    const publicBudgetInput = this.publicBudgetInputTarget
    const budgetBlank = !this.budgetPositiveValue
    const fullCondition = checked || budgetBlank
    this.publicBudgetWrapperTarget.classList.toggle('d-none', !fullCondition)
    publicBudgetInput.disabled = !fullCondition
    publicBudgetInput.setAttribute('required', fullCondition)
    if (this.hasBudgetHiddenInputTarget) this.budgetHiddenInputTarget.disabled = fullCondition
  }

  performPublicElements(checked) {
    this.performNameAttributes(checked)
    this.performBudgetAttributes(checked)
  }

  initializePublicCheckbox() {
    const checked = this.publicHiddenInputTarget.value === 'true'
    this.performPublicElements(checked)
  }

  togglePublicCheckbox(event) {
    const checked = event.target.checked
    this.performPublicElements(checked)
  }

  toggleAlreadyRadio(event) {
    const checked = event.target.value === 'true' // -> 'set_public'
    this.alreadyPublicWrapperTarget.classList.toggle('d-none', !checked)
    this.alreadyPublicNameInputTarget.disabled = !checked
    this.alreadyPublicBudgetInputTarget.disabled = !checked
    this.alreadyPublicNameInputTarget.setAttribute('required', checked)
    this.alreadyPublicBudgetInputTarget.setAttribute('required', checked)
    this.alreadyHiddenActionTarget.value = checked ? 'set_public' : 'add_invitation'
  }

  get formIdentifier() { return this.formTarget.id }
}