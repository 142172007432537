document.addEventListener("turbo:load", () => {
  // Проверяем браузер и статус разрешения на уведомления
  if ((navigator.userAgent.indexOf("Firefox") !== -1 || (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1)) && Notification.permission === 'default') {
    // Создаем подложку для диалогового окна
    const backdrop = document.createElement('div');
    backdrop.id = 'notificationBackdrop';
    backdrop.className = 'position-fixed top-0 start-0 w-100 h-100 bg-dark';
    backdrop.style.opacity = '0.5';
    backdrop.style.zIndex = '999';
    document.body.appendChild(backdrop);

    // Создаем диалоговое окно для объяснения необходимости подписки на уведомления
    const dialog = document.createElement('div');
    dialog.id = 'notificationDialog';
    dialog.className = 'position-fixed top-50 start-50 translate-middle p-4 bg-white shadow-xl';
    dialog.style.borderRadius = '16px';
    dialog.style.height = '270px';
    dialog.style.width = '400px';
    dialog.style.maxWidth = '90%';
    dialog.style.maxHeight = '90%';
    dialog.style.zIndex = '1000';
    dialog.innerHTML = `
      <div class="modal-header">
        <h5 class="modal-title">Підписка на сповіщення</h5>
        <button type="button" class="btn-close" id="closeNotificationDialog" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Ви хочете отримувати сповіщення в браузері, щоб не пропустити нічого важливо поки ви на Кідіумі?</p>
      </div>
      <div class="modal-footer">
        <button id="acceptNotificationsButton" class="btn btn-primary w-100 rounded-pill">Далі</button>
      </div>
    `;
    document.body.appendChild(dialog);

    document.getElementById('acceptNotificationsButton').addEventListener('click', async () => {
      try {
        // Запрашиваем разрешение на отправку уведомлений через стандартное браузерное окно
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Разрешение на отправку уведомлений получено.');
          // Вызываем метод для подписки на push-уведомления
          initPushSubscription();
        } else {
          console.log('Разрешение на отправку уведомлений отклонено пользователем.');
        }
      } catch (error) {
        console.error('Ошибка при запросе разрешения на уведомления:', error);
      } finally {
        document.body.removeChild(dialog);
        document.body.removeChild(backdrop);
      }
    });

    document.getElementById('closeNotificationDialog').addEventListener('click', () => {
      console.log('Пользователь отклонил предложение подписки на уведомления.');
      document.body.removeChild(dialog);
      document.body.removeChild(backdrop);
    });
  } else if (Notification.permission === 'granted') {
    // Если уведомления уже разрешены, можно сразу подписаться
    initPushSubscription();
  } else {
    console.log('Пользователь уже ранее отклонил разрешение на уведомления.');
  }

  // Після завантаження сторінки перевіряємо Bootstrap breakpoint і видаляємо одну із 2 форм швидкого пошуку,
  // яка не відповідає версії сайта (мобільна/десктопна). Визначення версій у search.scss
  // TODO eventListener on width change and move Dom element
  if (window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'mobile') {
    if (!!document.querySelector('.desktop-partial')) {
      document.querySelector('.desktop-partial').remove();
    }
  } else if (!!document.querySelector('.mobile-partial')) {
    document.querySelector('.mobile-partial').remove();
  }

  let searchMobile = document.querySelector('#search'); // Пошук по мобільному версії
  let btnSearchMobile = document.querySelector('#btnSearchMobile'); // Кнопка пошуку по мобільному версії
  let searchBlockMobile = document.querySelector('#searchBlockMobile'); // Блок пошуку по мобільному версії

  if (searchMobile && btnSearchMobile) {
    btnSearchMobile.addEventListener('click', (e) => {
      e.preventDefault();
      btnSearchMobile.classList.toggle('active');
      searchBlockMobile.classList.toggle('active');
      searchMobile.focus();
    });

    document.addEventListener('click', function (e) {
      if (!e.target.closest('.searchBlock')) {
        btnSearchMobile.classList.remove('active');
        searchBlockMobile.classList.remove('active');
      }
    });
  }
});

async function initPushSubscription() {
  if (!('Notification' in window)) return Promise.reject(false); // or Promise.resolve(false) or return

  const rowVapidPublicKey = document.querySelector("[name='vapid-public-key']").content;
  if (!rowVapidPublicKey) {
    console.warn('[Error] Vapid public key not present!');
    return;
  }

  const vapidPublicKey = new Uint8Array(JSON.parse(rowVapidPublicKey));

  if (navigator.serviceWorker) {
    try {
      await navigator.serviceWorker.register('/service-worker.js');

      console.log('Service Worker for web push notifications successfully registered!');
      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      if (serviceWorkerRegistration.pushManager) {
        const existingSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (existingSubscription) {
          // Если подписка существует, отписываемся
          await existingSubscription.unsubscribe();
          console.log('Старая подписка успешно отменена.');
        }

        // Создаём новую подписку с новым ключом
        const newSubscription = await serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey
        })

        const data = await fetch('/webpush_subscriptions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newSubscription),
        })

        console.log(await data.json())
      } else {
        console.log("[Safari] pushManager not available via web standard");
      }
    } catch (e) {
      console.error(e.toString());
    }
  }
}

window.addEventListener('load', () => {
  if (!navigator.serviceWorker) return false;

  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    let serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
    } else if (registration.active) {
      serviceWorker = registration.active;
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});

document.addEventListener("turbo:load", () => {
  let searchMobile = document.querySelector('#search'); // Пошук по мобільному версії
  let btnSearchMobile = document.querySelector('#btnSearchMobile'); // Кнопка пошуку по мобільному версії
  let searchBlockMobile = document.querySelector('#searchBlockMobile'); // Блок пошуку по мобільному версії

  if (searchMobile && btnSearchMobile) {
    btnSearchMobile.addEventListener('click', (e) => {
      e.preventDefault();
      btnSearchMobile.classList.toggle('active');
      searchBlockMobile.classList.toggle('active');
      searchMobile.focus();
    });

    document.addEventListener('click', function (e) {
      if (!e.target.closest('.searchBlock')) {
        btnSearchMobile.classList.remove('active');
        searchBlockMobile.classList.remove('active');
      }
    });

  }
});
