import $ from 'jquery'
import jQuery from 'jquery'

// КОСТИЛЬ, щоб ці таблиці працювали з bootstrap5 потрібно його ініціалізувати перед тим як імпортнуться таблиці , так само із іншими темами
// https://github.com/wenzhixin/bootstrap-table/issues/5837
window.bootstrap = {
  ...bootstrap,
  Tooltip: {
    VERSION: "5",
    ...bootstrap.Tooltip,
  }
}
require('bootstrap-table');
require("bootstrap-table/dist/extensions/page-jump-to/bootstrap-table-page-jump-to.min.js")

document.addEventListener('turbo:load', function () {
  initProfileBootstrapTable();
});

function initProfileBootstrapTable() {
  $('#services-table').bootstrapTable();
  $("#publish-select-services").on("click", function () {
    publishSelectObjects($('#services-table'), `/${currentLocale()}/services/publish_all`)
  })
  $("#unpublish-select-services").on("click", function () {
    unpublishSelectObjects($('#services-table'), `/${currentLocale()}/services/unpublish_all`)
  })

  $('#products-table').bootstrapTable();
  $("#publish-select-products").on("click", function () {
    publishSelectObjects($('#products-table'), `/${currentLocale()}/products/publish_all`)
  })
  $("#unpublish-select-products").on("click", function () {
    unpublishSelectObjects($('#products-table'), `/${currentLocale()}/products/unpublish_all`)
  })

  $('#posts-table').bootstrapTable();
  $("#publish-select-posts").on("click", function () {
    publishSelectObjects($('#posts-table'), `/${currentLocale()}/posts/publish_all`)
  })
  $("#unpublish-select-posts").on("click", function () {
    unpublishSelectObjects($('#posts-table'), `/${currentLocale()}/posts/unpublish_all`)
  })
}

window.initProfileBootstrapTable = initProfileBootstrapTable;

function initBestInPlace() {
  // jQuery(".best_in_place").best_in_place();
}

window.initBestInPlace = initBestInPlace

function publishSelectObjects(table, url) {
  let ids = []
  $(table).bootstrapTable('getSelections').forEach((row) => {
    ids.push(row.id)
  })
  ajax({
    url: url,
    type: 'put',
    data: {
      ids
    },
    success: function (data) {
      toaster('success', data.flash_status, data.flash_text)
      $(table).bootstrapTable('refresh')
    },
    error: function (data) {
      toaster('alert', data.flash_status, data.flash_text)
    }
  });
}

function unpublishSelectObjects(table, url) {
  let ids = []
  $(table).bootstrapTable('getSelections').forEach((row) => {
    ids.push(row.id)
  })
  ajax({
    url: url,
    type: 'put',
    data: {
      ids
    },
    success: function (data) {
      toaster('success', data.flash_status, data.flash_text);
      $(table).bootstrapTable('refresh')
    },
    error: function (data) {
      toaster('alert', data.flash_status, data.flash_text);
    }
  });
}

function imageFormatter(value, row, index) {
  if (row.image == undefined) {
    return "<img src='/undefined.png'>"
  } else {
    return `<img src=${row.image} width='100px' height='100px'>`
  }
}

window.imageFormatter = imageFormatter;

function publishedFormatter(value, row, index) {
  const t = I18n.profile.operation

  let eye_icon_class = "";
  let published_title = t.unpublished
  if (!row.is_published) {
    eye_icon_class = "disabled";
    published_title = t.published
  }
  return [
    `<a class="table-link published" href="javascript:void(0)" title="${published_title}">`,
    `<i class="fa fa-eye ${eye_icon_class}"id="${row.class}_${row.id}_published"></i>`,
    '</a>'
  ].join('')
}

window.publishedFormatter = publishedFormatter;

function operateFormatter(value, row, index) {
  const classToFrame = {
    'post': 'post_modal',
    'service': 'modal',
  };
  const frame = classToFrame[row.class] || '';
  const t = I18n.profile.operation
  const target = row.class === 'service' ? 'target="self"' :
    row.class === 'product' ? 'target="_top"' : '';
  const dataAction = row.class == 'post' ? 'click->post-delete#deleteProfilePost' : ''
  const dataController = row.class == 'post' ? 'post-delete' : ''
  return [
    `<a class="table-link like" href="/${row.class}s/${row.id}" title="${t.show}" target="_top">`,
    '<i class="fa fa-external-link-alt"></i>',
    '</a>  ',
    `<a class="table-link edit" ${target} href="/${row.class}s/${row.id}/edit" data-turbo-frame="${frame}" title="${t.edit}">`,
    '<i class="fa fa-pencil-alt"></i>',
    '</a>',
    `<a class="table-link delete" data-controller='${dataController}' data-action='${dataAction}' data-confirm="${t.delete.confirm}" data-method="delete" href="/${row.class}s/${row.id}" title="${t.delete.title}" rel="nofollow">`,
    '<i class="fa fa-trash"></i>',
    '</a>'
  ].join('')
}

window.operateFormatter = operateFormatter;

const bootstrapTablePublishedEvents = {
  'click .published': function (event, value, row, index) {
    const t = I18n.profile.operation

    let link = event.currentTarget
    let icon = link.querySelector("svg");

    ajax({
      url: `/${currentLocale()}/${row.class}s/${row.id}/set_published`,
      type: 'put',
      data: {
        is_published: !row.is_published
      },
      success: function (data) {
        if (row.is_published) {
          link.title = t.unpublished
          icon.classList.add("disabled");
        } else {
          link.title = t.published
          icon.classList.remove("disabled");
        }
        row.is_published = !row.is_published
        toaster('success', data.flash_status, data.flash_text);
      },
      error: function (data) {
        toaster('alert', data.flash_status, data.flash_text);
      }
    });
  }
}
window.bootstrapTablePublishedEvents = bootstrapTablePublishedEvents;
