import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["sendCode", "verifyCode", "verificationCodeSection", "userName", "userEmail", "userPhone", "codeInput", "resendCode"];
  static values = {
    registerUrl: String,
    verifyUrl: String,
    uuid: String
  };

  async registerContact(event) {
    event.preventDefault();
    const firstName = this.userNameTarget.value;
    const email = this.userEmailTarget.value;
    const phone = this.userPhoneTarget.value;

    let isValid = true;

    if (!firstName) {
      this.setError(this.userNameTarget, "This field cannot be empty");
      isValid = false;
    } else {
      this.clearError(this.userNameTarget);
    }

    if (!email) {
      this.setError(this.userEmailTarget, "This field cannot be empty");
      isValid = false;
    } else {
      this.clearError(this.userEmailTarget);
    }

    const phonePattern = /^\+38\(0\d{2}\)\d{3}-\d{2}-\d{2}$/;
    if (phone && !phonePattern.test(phone)) {
      this.setError(this.userPhoneTarget, "Phone number must be in the format +38(0XX)XXX-XX-XX");
      isValid = false;
    } else {
      this.clearError(this.userPhoneTarget);
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await post(this.registerUrlValue, {
        body: JSON.stringify({ user: { first_name: firstName, email, phone } }),
        contentType: "application/json",
        responseKind: "json"
      });

      if (response.ok) {
        alert("Verification code sent");
        this.verificationCodeSectionTarget.classList.remove("d-none");
        this.sendCodeTarget.classList.add("d-none");
      } else {
        const errorData = await response.json;
        if (errorData && errorData.errors) {
          this.handleErrors(errorData.errors);
        } else {
          alert("Unknown error");
        }
      }
    } catch (error) {
      console.error("Error registering contact:", error);
      alert("An error occurred while processing your request. Please try again later.");
    }
  }

  async verifyUser(event) {
    event.preventDefault();
    const uuid = this.uuidValue;
    const email = this.userEmailTarget.value;
    const phone = this.userPhoneTarget.value;
    const code = this.codeInputTargets.map((input) => input.value).join("");

    if (code.length < 6) {
      alert("Please enter the complete verification code");
      return;
    }

    try {
      const response = await post(this.verifyUrlValue, {
        body: JSON.stringify({ email, phone, code, uuid }),
        contentType: "application/json",
        responseKind: "json"
      });

      if (response.ok) {
        console.log("User verified successfully");
        window.location.reload();
        alert("User verified successfully");
      } else {
        const errorData = await response.json;
        if (errorData && errorData.errors) {
          this.handleVerificationErrors(errorData.errors);
        } else {
          alert("An unknown error occurred. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error verifying user:", error);
      alert("An error occurred while verifying the user. Please try again later.");
    }
  }

  handleErrors(errors) {
    Object.keys(errors).forEach(attribute => {
      const error = errors[attribute][0];
      if (attribute === "first_name") {
        this.setError(this.userNameTarget, "Error in user name. Please check the entered data!");
      } else if (attribute === "email") {
        if (error.error === "already_sent") {
          alert("Verification code already sent. Please check your email!");
        } else {
          this.setError(this.userEmailTarget, "Error in email. Please check the entered data!");
        }
      } else if (attribute === "phone") {
        if (error.error === "taken") {
          this.setError(this.userPhoneTarget, "Phone number is already in use. Please enter a different number.");
        } else {
          this.setError(this.userPhoneTarget, "Error in phone number. Please check the entered data!");
        }
      } else {
        alert("Unknown error");
      }
    });
  }

  handleVerificationErrors(errors) {
    Object.keys(errors).forEach(attribute => {
      const error = errors[attribute][0];
      if (error.error === 'expired') {
        alert("Verification code expired. Please request a new one.");
      } else if (error.error === 'missed') {
        alert("Verification code or user email is missing.");
      } else if (error.error === 'invalid') {
        alert("Invalid verification code. Please try again.");
      } else {
        alert("An unknown error occurred. Please try again.");
      }
    });
  }

  setError(element, message) {
    element.classList.add("is-invalid");
    element.classList.add("border", "border-danger");
    let errorElement = element.nextElementSibling;
    if (!errorElement || !errorElement.classList.contains("invalid-feedback")) {
      errorElement = document.createElement("div");
      errorElement.classList.add("invalid-feedback");
      element.parentNode.appendChild(errorElement);
    }
    errorElement.textContent = message;
  }

  clearError(element) {
    element.classList.remove("is-invalid");
    element.classList.remove("border", "border-danger");
    const errorElement = element.nextElementSibling;
    if (errorElement && errorElement.classList.contains("invalid-feedback")) {
      errorElement.remove();
    }
  }

  handleInput(event) {
    const value = event.target.value.replace(/\D/g, "").charAt(0);
    event.target.value = value || "";

    const index = this.codeInputTargets.indexOf(event.target);
    if (value && index < this.codeInputTargets.length - 1) {
      this.codeInputTargets[index + 1].focus();
    }
  }

  handleBackspace(event) {
    if (event.key === "Backspace" && !event.target.value) {
      const index = this.codeInputTargets.indexOf(event.target);
      if (index > 0) {
        this.codeInputTargets[index - 1].focus();
      }
    }
    if (event.key === "Escape") {
      this.clearCodeInputs();
    }
  }

  handlePaste(event) {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("text");
    if (/^\d{6}$/.test(clipboardData)) {
      this.clearCodeInputs();
      clipboardData.split("").forEach((char, i) => {
        this.codeInputTargets[i].value = char;
      });
      this.codeInputTargets[this.codeInputTargets.length - 1].focus();
    } else {
      alert("The verification code must consist of digits only");
    }
  }

  clearCodeInputs() {
    this.codeInputTargets.forEach((input) => (input.value = ""));
    this.codeInputTargets[0].focus();
  }
}