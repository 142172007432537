import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from "@rails/activestorage"
import fadeIn from "controllers/_lib/animate/fadeIn.js"
import fadeOut from "controllers/_lib/animate/fadeOut.js"
import { showLoader, hideLoader } from 'controllers/_lib/loader/loaderLibrary';
import { deleteBlobByIdWithFetch } from "controllers/_lib/destroy/deleteBlobById.js" // переписав на fetch

export default class extends Controller {
  static targets = [
    'paymentOptionsRadio',
    'paymentOptionsSelect',
    'ageToSelect',
    'ageFromSelect',
    'resetButton',
    'onlineTypeSelect',
    'addCityButton',
    'countrySelect',
    'citySelect',
    'removeButton',
    'removeCitySelectButton'
  ]

  static values = {
    url: String,
    persisted: Boolean,
    turboFrame: { type: String, default: 'modal' }
  }

  initialize() {
    this.initialToOptions = this.ageToSelectTarget.querySelectorAll("option");
    this.initialFromOptions = this.ageFromSelectTarget.querySelectorAll("option");
  }

  connect() {
    this.onlineTypePerform()
    this.updateRemoveButtonVisibility();
    this.updateResetButtonVisibility();
  }

  showLoader() {
    showLoader()
  }

  hideLoader() {
    hideLoader()
  }

  updateCityInputOnCreate(onlineSelected) {
    this.citySelectTargets.forEach((citySelect) => {
      if (onlineSelected) {
        if (citySelect.id !== 'city-select-1') {
          citySelect.closest('.form-group.service_city_ids').remove();
        } else {
          const clearInput = citySelect.closest('.input-group.cities-group').querySelector('.clear-button')
          if (clearInput) clearInput.click()
          citySelect.removeAttribute("required")
        }
      } else {
        citySelect.setAttribute("required", "")
      }
    });
  }

  updateCityInputOnUpdate(onlineSelected) {
    this.citySelectTargets.forEach((citySelect) => {
      const formGroup = citySelect.closest('.form-group.service_city_ids');
      if (onlineSelected) {
        if (citySelect.id !== 'city-select-1') {
          // Вимикаємо всі елементи форми, крім головного вибору міста
          this.setDisabled(formGroup, true);
        } else {
          citySelect.removeAttribute("required");
          // Включаємо головний вибір міста
          this.setDisabled(citySelect, false);
        }
      } else {
        citySelect.setAttribute("required", "");
        // Включаємо всі елементи форми
        this.setDisabled(formGroup, false);
      }
    });
  }

  setDisabled(element, disabled) {
    if (disabled) {
      element.querySelectorAll('select, input').forEach(el => el.disabled = true);
    } else {
      element.querySelectorAll('select, input').forEach(el => el.disabled = false);
    }
  }

  onlineTypePerform() {
    const onlineSelected = this.onlineTypeSelectTarget.value === "false"
    const citySelectWrappers = this.citySelectTargets
    const countrySelectWrappers = this.countrySelectTargets
    const resetButtons = this.removeCitySelectButtonTargets

    if (onlineSelected) {
      citySelectWrappers.forEach((citySelectWrapper) => {
        fadeOut(citySelectWrapper.closest('.input-group.cities-group'), 100, 'd-none')
      })
      resetButtons.forEach((resetButton) => {
        fadeOut(resetButton, 100, 'd-none')
      })
      fadeOut(this.addCityButtonTarget, 100, 'd-none')
    } else {
      citySelectWrappers.forEach((citySelectWrapper) => {
        fadeIn(citySelectWrapper.closest('.input-group.cities-group'), 400, 'd-none')
      })
      resetButtons.forEach((resetButton) => {
        fadeIn(resetButton, 400, 'd-none')
      })
      fadeIn(this.addCityButtonTarget, 500, 'd-none')
    }

    this.persistedValue ? this.updateCityInputOnCreate(onlineSelected) : this.updateCityInputOnUpdate(onlineSelected)
  }

  updateAgeToOptions() {
    const _this = this
    const selectedAgeFrom = parseInt(this.ageFromSelectTarget.value);

    if (Number.isNaN(selectedAgeFrom)) {
      this.updateResetButtonVisibility();
      return this.resetSelectOptions(this.ageToSelectTarget, this.initialToOptions);
    }

    const selectedAgeTo = parseInt(this.ageToSelectTarget.value);
    let selectedValue = selectedAgeTo; // Save the selected value
    this.ageToSelectTarget.innerHTML = "";

    this.initialToOptions.forEach((option) => {
      const optionAge = +option.value;

      // Add prompt option if ageFrom is not selected
      if (!selectedAgeTo && optionAge === 0) {
        _this.ageToSelectTarget.appendChild(option.cloneNode(true));
      }

      // limit ageTo options by ageFrom
      if (optionAge > selectedAgeFrom && (!selectedAgeTo || optionAge <= selectedAgeTo)) {
        _this.ageToSelectTarget.appendChild(option.cloneNode(true));
      }
    });

    // Ensure selectedValue is not less than selectedAgeFrom
    if (!Number.isNaN(selectedValue)) {
      selectedValue = selectedValue < selectedAgeFrom ? selectedAgeFrom : selectedValue;
      this.ageToSelectTarget.value = selectedValue; // Restore the selected value
    }

    this.updateResetButtonVisibility();
  }

  updateAgeFromOptions() {
    const _this = this
    const selectedAgeFrom = parseInt(this.ageFromSelectTarget.value);
    const selectedAgeTo = parseInt(this.ageToSelectTarget.value);
    this.ageFromSelectTarget.innerHTML = "";

    this.initialFromOptions.forEach((option) => {
      const optionAge = +option.value;

      if (optionAge < selectedAgeTo && (!selectedAgeFrom || optionAge >= selectedAgeFrom)) {
        _this.ageFromSelectTarget.appendChild(option.cloneNode(true));
      }
    });
    this.updateResetButtonVisibility();
  }

  updateResetButtonVisibility() {
    const selectedAgeFrom = parseInt(this.ageFromSelectTarget.value);
    const selectedAgeTo = parseInt(this.ageToSelectTarget.value);

    if (Number.isNaN(selectedAgeFrom) && Number.isNaN(selectedAgeTo)) {
      fadeOut(this.resetButtonTarget, 300, 'd-none')
    } else {
      fadeIn(this.resetButtonTarget, 600, 'd-none')
    }
  }

  resetSelectOptions(selectTarget, options) {
    selectTarget.innerHTML = "";
    options.forEach((option) => {
      const optionElement = document.createElement('option');
      optionElement.value = option.value;
      optionElement.text = option.text;
      selectTarget.appendChild(optionElement);
    });
  }

  resetAgeFields(event) {
    // Видаляємо залишкові опції і відновлюємо оригінальні опції
    event.preventDefault();
    this.resetSelectOptions(this.ageFromSelectTarget, this.initialFromOptions);
    this.resetSelectOptions(this.ageToSelectTarget, this.initialToOptions);
    this.updateResetButtonVisibility();
  }

  imageLoad(event) {
    const input = event.target;
    this.file = input.files && input.files[0];

    if (this.file?.size > 10 * 1024 * 1024) {
      alert('Увага! Максимальнтй размір зображення - 10 МБ.');
      return;
    }

    const label = document.querySelector(`label[for="${input.id}"]`);
    const removeButton = label.querySelector('.remove-button')
    const preview = label.querySelector('.preview .preview-container');
    const progressWrapper = label.querySelector('.progress');

    if (this.file) {
      progressWrapper?.classList.remove('d-none');
      this.submitButton.disabled = true;
      this.submitButton.classList.add('opacity-50');
      this.label = label;
      preview.innerHTML = `<img src="${URL.createObjectURL(this.file)}" class="service-image-preview proportional-fit">`;
      preview.classList.add('opacity-50');
      const url = input.dataset.directUploadUrl
      const upload = new DirectUpload(
        this.file,
        url,
        this
      )

      upload.create((error, blob) => {
        if (error) {
          console.error(error);
          preview.innerHTML = this.plusIcon;
          removeButton.dataset.imagePresent = "false";
        } else {
          const hiddenField = document.createElement('input');
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = input.name;
          document.querySelector('form').appendChild(hiddenField);
          removeButton.dataset.imagePresent = "true";
        }

        progressWrapper?.classList.add('d-none');
        progressWrapper.querySelector('.progress-bar').style.width = '0%';
        preview.classList.remove('opacity-50');
        this.submitButton.classList.remove('opacity-50')
        this.submitButton.disabled = false;
        this.updateRemoveButtonVisibility();
      });
    } else {
      removeButton.dataset.imagePresent = "false";
      this.updateRemoveButtonVisibility();
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => {
      this.directUploadDidProgress(event);
    });
  }

  directUploadDidProgress(event) {
    const progress = event.loaded / event.total * 100;
    const progressBar = this.label.querySelector('.progress-bar');
    console.log('progress: ', progress);
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
    }
  }

  removeAttach(event) {
    event.preventDefault()
    const target = event.currentTarget
    const targetId = event.currentTarget.dataset.targetInput
    const input = document.querySelector(`#${targetId}`)
    const preview = target.closest('.preview').querySelector('.preview-container')
    if (target.dataset.imagePresent == "true" && target.dataset.objectPersisted == "true") {
      const blobId = preview.querySelector('.service-image-preview').dataset.inputId
      if (blobId) this.handleDeleteBlob(blobId)
    }
    preview.innerHTML = this.plusIcon;
    input.value = null
    this.imageLoad({ target: input })
  }

  updateRemoveButtonVisibility() {
    this.removeButtonTargets.forEach((button) => {
      const imagePresent = button.dataset.imagePresent === "true";
      button.classList.toggle("d-none", !imagePresent);
    });
  }

  async handleDeleteBlob(blobId) {
    try {
      const result = await deleteBlobByIdWithFetch(blobId);

      if (result.success) {
        console.log('Deletion was successful:', result.data);
      } else {
        console.error('Deletion failed:', result.error);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  }

  get plusIcon() {
    if (!this._plusIconTemplate) {
      const template = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 opacity-50">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
      </svg>
    `;
      this._plusIconTemplate = template;
    }
    return this._plusIconTemplate;
  }

  get submitButton() {
    return document.querySelector('#submit-button');
  }

  get loader() {
    return document.querySelector('#turbo-loader-backdrop');
  }
}
