import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.performAfterRedirectAction();
  }

  performAfterRedirectAction() {
    const afterRedirectAction = localStorage.getItem('afterRedirectAction');
    if (afterRedirectAction) {
      const { action, target } = JSON.parse(afterRedirectAction);

      if (action === 'click_link') {
        const element = document.querySelector(target);
        if (element) element.click();
      }

      localStorage.removeItem('afterRedirectAction');
    }
  }
}