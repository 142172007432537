import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'template']

  addCityInput(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/new_/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  removeCityInput(event) {
    event.preventDefault()
    const button = event.currentTarget
    const locationFields = button.closest('.location-fields')

    if (!locationFields) return

    const inputLength = document.querySelectorAll('.location-fields').length
    if (inputLength < 2) {
      const t = I18n.services.form.city_input
      toaster('error', t.error, t.least_one_city)
      console.log('You need at least one city')
      return
    }

    locationFields.remove()
  }
}
